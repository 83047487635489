<template>
  <div>
  <div v-if="value != null" >
    <b-iconstack :font-scale="fontScale" :class="level" >

      <b-icon v-show="isBallet && isPrecision" stacked :icon="precisionIcon" shift-h="-3" shift-v="-2" scale="0.5" :variant="cVariant"></b-icon>
      <b-icon v-show="isBallet && isPrecision" stacked :icon="balletIcon" shift-h="1" shift-v="2" scale="0.5" :variant="cVariant"></b-icon>

      <b-icon v-show="isBallet && !isPrecision" stacked :icon="balletIcon" scale="0.5" :variant="cVariant"></b-icon>
      <b-icon v-show="isPrecision && !isBallet" stacked :icon="precisionIcon" scale="0.5" :variant="cVariant"></b-icon>

      <b-icon stacked :icon="borderIcon" :variant="bVariant"></b-icon>
    </b-iconstack>
  </div>
  </div>
</template>

<script>
import { REF_EVENT } from "@/LocalDB";

export default{
  name:"IconEventType",
  props:{
    value: { type:String, required:true }, // Code de l'épreuve
    level: { type:String, default:"" },
    size: { type:String, default:''},
    variant: { type:String, default:''},
    borderVariant: { type:String, required:false},
    contentVariant: { type: String, required:false},
    //event: { type:String, default:'ballet'},
  },
  computed:{
    borderIcon(){
      return "square";
    },
    balletIcon(){
      return 'music-note-beamed';
    },
    precisionIcon(){
      return 'x-diamond-fill'; //'heptagon';
    },
    fontScale(){
      switch(this.size)
      {
        case "xsm": return 1;
        case "sm": return 2;
        case "lg": return 4;
        case "xl": return 5;
        case "xxl": return 6;
        default: return 3;
      }
    },
    bVariant(){
      return (this.borderVariant != null) ? this.borderVariant : this.variant;
    },
    cVariant(){
      return (this.contentVariant != null) ? this.contentVariant : this.variant;
    },
    EVENT(){
      if(this.value != null)
        return REF_EVENT.query().where("code", this.value).first();
      return { hasBallet: false, hasCompulsories: false, hasRoutine: false};
    },
    isBallet(){
      return this.EVENT.hasBallet;
    },
    hasRoutine(){
      return this.EVENT.hasRoutine;
    },
    hasCompulsories(){
      return this.EVENT.hasCompulsories;
    },
    isPrecision(){
      return this.hasCompulsories || this.hasRoutine;
    },
  },
}
</script>

<style scoped>
  .expert { background-color:gold; }
  .inter { background-color:silver; }
  .novice { background-color: #bf8970 }
</style>

